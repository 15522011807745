import React, { useState, useEffect, useMemo, CSSProperties } from "react"
import Styles from "../styles/SelectBoxWithInputSearch.module.scss";
import SelectButton from "../components/atoms/SelectButton";
import { isStringLabelSelectButtonTypeList, SelectButtonType, SelectType, StringLabelSelectButtonType } from "../types/models/SelectButton";
import { Tag } from "../types/models/Tag";
import { DownArrowIcon } from "../icons/DownArrowIcon";
import { UpArrowIcon } from "../icons/UpArrowIcon";

type Props<T extends string | number> = {
    buttonList: SelectButtonType<T>[],
    onChangeSelectButton: ( name: string, buttonList: SelectButtonType<T>[] ) => void,
    type: string,
    label: string,
    labelStyle?: CSSProperties,
    buttonLabel?: string,
    boxName: string,
    placeholder: string,
    style: CSSProperties,
    defaultChecked?: boolean
}

const SelectBoxWithInputSearch = <T extends string | number> ( {
    buttonList,
    onChangeSelectButton,
    type,
    label,
    labelStyle,
    buttonLabel,
    boxName,
    placeholder,
    style,
    defaultChecked = false
}: Props<T> ) => {

    const [ text, setText ] = useState( "" )
    const [ matchedButtonList, setMatchedButtonList ] = useState<SelectButtonType<T>[]>( [] )
    const [ unMatchedButtonList, setUnMatchedButtonList ] = useState<SelectButtonType<T>[]>( buttonList )

    useEffect( () => onChangeText( text ), [ buttonList ] )

    const onChangeSelect = ( name: string, value: T, isSelected: boolean ) => {
        let newButtonList
        if ( type === SelectType.CHECKBOX ) {
            newButtonList = buttonList.map( button =>
                button.value === value ?
                    { ...button, isSelected } :
                    button
            )
        }
        else {
            newButtonList = buttonList.map( button =>
                button.value === value ?
                    { ...button, isSelected: true } :
                    { ...button, isSelected: false }
            )
        }
        onChangeSelectButton( boxName, newButtonList )
    }

    const onChangeText = ( text: string ) => {
        setText( text )
        if ( text === "" ) {
            setMatchedButtonList( [] )
            setUnMatchedButtonList( buttonList )
            return
        }

        if ( isStringLabelSelectButtonTypeList( buttonList ) ) {
            setMatchedButtonList( ( buttonList as any[] ).filter( button => button.label.includes( text ) ) )
            setUnMatchedButtonList( ( buttonList as any[] ).filter( button => !button.label.includes( text ) ) )
        }
    }

    const SelectedButtonList = useMemo( () =>
        <div className={ `${ Styles.button_list_wrapper }` }>
            { buttonList.filter( button => button.isSelected ).map( button =>
                <SelectButton
                    key={ button.value }
                    style={ { marginLeft: 8 } }
                    name={ boxName }
                    label={ button.label }
                    value={ button.value }
                    // type={ type }
                    isSelected={ button.isSelected }
                    onChange={ onChangeSelect } /> ) }
        </div>, [ buttonList ] )


    const MatchedButtonList = useMemo( () =>
        <div className={ `${ Styles.button_list_wrapper } ${ Styles.matched_button_list_wrappper }` }>
            { matchedButtonList.map( button => (
                <SelectButton
                    key={ button.value }
                    style={ { marginLeft: 8 } }
                    name={ boxName }
                    label={ button.label }
                    value={ button.value }
                    // type={ type }
                    isSelected={ button.isSelected }
                    onChange={ onChangeSelect } />
            ) ) }
        </div>, [ matchedButtonList ] )

    const UnMatchedButtonList = useMemo( () =>
        <div className={ `${ Styles.button_list_wrapper } ${ Styles.unmatched_button_list_wrapper }` }>
            { unMatchedButtonList.map( button =>
                <SelectButton
                    key={ button.value }
                    style={ { marginLeft: 8 } }
                    name={ boxName }
                    label={ button.label }
                    value={ button.value }
                    // type={ type }
                    isSelected={ button.isSelected }
                    onChange={ onChangeSelect } /> ) }
        </div>, [ unMatchedButtonList ] )

    return (
        <div className={ Styles.container } style={ style }>
            <label 
                className={ Styles.label } 
                style={ labelStyle }>
                    { `${ label }を選択` }
            </label>
            { SelectedButtonList }
            <input
                className={ Styles.text_input }
                type="text"
                value={ text }
                placeholder={ placeholder }
                onChange={ ( e ) => onChangeText( e.target.value ) } />
            { MatchedButtonList }
            <input id={ `all_${ label }` } className={ Styles.toggle_input } type="checkbox" defaultChecked={ defaultChecked } />
            <label
                htmlFor={ `all_${ label }` }
                className={ `${ Styles.toggle_label } ${ boxName === Tag.MYTAG ? Styles.accent_color : Styles.main_color }` }>
                { `すべての${ buttonLabel || label }` }
                <span className={ `${ Styles.icon_down } ${ boxName === Tag.MYTAG ? Styles.accent_color : Styles.main_color }` }>
                    を表示
                </span>
                <DownArrowIcon className={ `${ Styles.icon_down } ${ Styles.icon }` } />
                <UpArrowIcon className={ `${ Styles.icon_up } ${ Styles.icon }` } />
            </label>
            { UnMatchedButtonList }
        </div>
    )
}

export default SelectBoxWithInputSearch
