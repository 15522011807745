import { AxiosPromise } from 'axios';
import client from '../client';


type Params = {
    data: FormData
    onUpload: ( e: ProgressEvent ) => void
}

type ResponseType = {
    postUuid: string
    commentUuidList: string[]
}

export const createPost = ( { data, onUpload }: Params ): AxiosPromise<ResponseType> => {
    return client.post( `/api/post/`, data, {
        onUploadProgress: onUpload
    } )
}
