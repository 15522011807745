import { useState, useEffect } from 'react'
import { getVideoPermission } from '../apis/post/getPermission';



export const useVideoUploadPermission = () => {

    const [ canUploadVideo, setCanUploadVideo ] = useState( true );

    useEffect( () => {
        fetchAndSetCanUploadVideo()
    }, [] )

    const fetchAndSetCanUploadVideo = async () => {
        try {
            const { data } = await getVideoPermission()
            setCanUploadVideo( data.canUploadVideo )
        }
        catch ( error ) {
            console.warn( error )
        }
    }


    return {
        canUploadVideo,
    }
}
