import { DragEvent as ReactDragEvent, useEffect } from "react"


export const useFileDropForDocument = ( setFile: ( file: File ) => void ) => {

    useEffect( () => {
        document.addEventListener( "drop", dropFile )
        document.addEventListener( "dragover", handleDragOver )
        return () => {
            document.removeEventListener( "drop", dropFile )
            document.removeEventListener( "dragover", handleDragOver )
        }
    }, [] )

    const dropFile = ( e: DragEvent ) => {
        e.preventDefault()
        e.stopPropagation()
        if ( !e.dataTransfer ) return

        const files = e.dataTransfer.files
        setFile( files[ 0 ] )
    }

    const handleDragOver = ( e: DragEvent ) => {
        e.preventDefault()
        e.stopPropagation()
    }
}

export const useFileDrop = ( setFile: ( file: File ) => void ) => {

    const dropFile = ( e: ReactDragEvent<HTMLElement> ) => {
        e.preventDefault()
        e.stopPropagation()
        if ( !e.dataTransfer ) return

        const files = e.dataTransfer.files
        setFile( files[ 0 ] )
    }

    const preventEvents = ( e: ReactDragEvent<HTMLElement> ) => {
        e.preventDefault()
        e.stopPropagation()
    }

    return {
        onDrop: dropFile,
        onDragEnter: preventEvents,
        onDragOver: preventEvents
    }
}


export const useFileListDrop = ( setFileList: ( file: FileList ) => void ) => {

    const dropFile = ( e: ReactDragEvent<HTMLElement> ) => {
        e.preventDefault()
        e.stopPropagation()
        if ( !e.dataTransfer ) return

        const files = e.dataTransfer.files
        setFileList( files )
    }

    const preventEvents = ( e: ReactDragEvent<HTMLElement> ) => {
        e.preventDefault()
        e.stopPropagation()
    }

    return {
        onDrop: dropFile,
        onDragEnter: preventEvents,
        onDragOver: preventEvents
    }
}
