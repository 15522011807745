import React, { useRef, useMemo, FC } from "react"
import Styles from "../styles/LocalFile.module.scss"
import clsx from "clsx"
import { CloseIcon } from "../icons/CloseIcon"

type Props = {
    fileURL: string,
    fileType: string,
    getVideoDuration: ( duration: number ) => void,
    removeFile: () => void
}

const RemoteFile: FC<Props> = ( {
    fileURL,
    fileType,
    getVideoDuration,
    removeFile
} ) => {

    const localVideo = useRef<HTMLVideoElement>( null )

    const ImageMemo = useMemo( () =>
        <img
            src={ fileURL }
            className={ Styles.image }
            alt="" />
        , [ fileURL ] )

    const VideoMemo = useMemo( () =>
        <video
            ref={ localVideo }
            className={ Styles.video }
            muted={ true }
            src={ fileURL }
            onLoadedData={ () => {
                if ( !localVideo.current ) return
                getVideoDuration( localVideo.current.duration )
                localVideo.current.play()
                setTimeout( () => {
                    localVideo.current && localVideo.current.pause()
                }, 50 )
            } }
            controls>
        </video>, [ fileURL ] )

    const renderFile = () => {
        if ( fileType == "image" ) return ImageMemo
        else if ( fileType == "video" ) return VideoMemo
    }

    return (
        <div className={ Styles.container } >
            { renderFile() }
            <div
                className={ clsx( Styles.icon_container, Styles.remove_file_icon_container ) }
                onClick={ removeFile }>
                <CloseIcon
                    className={ Styles.remove_file_icon }/>
            </div>
        </div>
    )
}

export default RemoteFile
