import { Overwrite } from "../utils"

export type SelectButtonType<T extends string | number> = {
    // name: string
    label: string | JSX.Element
    value: T
    isSelected: boolean
    iconLeft?: JSX.Element
    icon?: JSX.Element
}

export type StringLabelSelectButtonType<T extends string | number> = Overwrite<SelectButtonType<T>, {label:string}>
export const isStringLabelSelectButtonTypeList = ( buttonList: any[] ): buttonList is StringLabelSelectButtonType<string | number>[] => 
    !!buttonList.length && typeof buttonList[0].label === "string"

export const SelectType = {
    CHECKBOX: "checkbox",
    RADIO: "radio"
} as const
export type SelectTypeType = typeof SelectType[ keyof typeof SelectType ]
