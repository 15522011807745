import React, { FC } from "react"
import Styles from "../styles/ConfigList.module.scss";
import SelectButton from "../components/atoms/SelectButton";
import { ConfigType } from "../types/models/Config";

type Props = {
    title: string
    description?: string,
    configList: ConfigType[],
    onChangeConfigButton: ( name: string, value: string, isSelected:boolean ) => void
}

const ConfigList: FC<Props> = ( {
    title,
    description,
    configList,
    onChangeConfigButton
} ) =>
    <div className={ Styles.config_container }>
        <p>{ title }</p>
        <p className={ Styles.description }>{ description }</p>
        <ul className={ Styles.config_list }>
            { configList.map( ( range, index ) =>
                <li className={ Styles.config_list_item } key={ index }>
                    <span>{ range.label }</span>
                    <div className={ Styles.button_container }>
                        { range.list.map( ( option ) =>
                            <SelectButton
                                key={ option.value }
                                style={ { marginLeft: "8px" } }
                                label={ option.label }
                                name={ range.name }
                                value={ option.value }
                                isSelected={ option.isSelected }
                                onChange={ onChangeConfigButton } />
                        ) }
                    </div>
                </li>
            ) }
        </ul>
    </div>

export default ConfigList
