import { useContext, useState } from 'react'
import axios from "axios"
import { updateComment } from '../apis/comment/updateComment'
import { createPost } from "../apis/post/createPost"
import { ToastContext } from '../context/ToastContext';
import { PickedMediaType } from '../types/models/Media/Media';

type Props = {
    mediaList: File[]
}

export const useMediaMultiUpload = ( { mediaList }: Props ) => {

    const [ postUuid, setPostUuid ] = useState( "" );
    const [ commentPkList, setCommentPkList ] = useState( [] );
    const [ commentUploadRatioList, setCommentUploadRatioList ] = useState<number[]>( [] );
    const { addToast } = useContext( ToastContext )

    const onUploadEachComment = ( e: ProgressEvent, index: number ) => {
        setCommentUploadRatioList(
            prevCommentUploadRatioList =>
                prevCommentUploadRatioList.map(
                    ( ratio, i ) => ( i === index ) ?
                        e.loaded / e.total :
                        ratio ) )
    }

    const uploadPostAndAllFiles = async ( { postFormData, onPostUpload }: { postFormData: FormData, onPostUpload: ( e: ProgressEvent ) => void } ) => {
        const createdCommentUuidList = await createPostRequest( postFormData, onPostUpload )
        if ( !createdCommentUuidList ) return
        await updateComments( createdCommentUuidList )
    }

    const createPostRequest = async ( postFormData: FormData, onPostUpload: ( e: ProgressEvent ) => void ) => {
        try {
            postFormData.append( "add_comments_number", String( mediaList.length ) )
            const { data: { postUuid: createdPostUuid, commentUuidList: createdCommentUuidList } } = await createPost( {
                data: postFormData,
                onUpload: onPostUpload
            } )
            setPostUuid( createdPostUuid )
            return createdCommentUuidList
        }
        catch ( error ) {
            addToast( {
                text: "投稿に失敗しました",
                type: "error",
                position: "center"
            } )
            console.warn( error )
            console.warn( error.response.data )
        }
    }

    const updateComments = async ( createdCommentPkList: string[] ) => {
        try {
            setCommentUploadRatioList( createdCommentPkList.map( i => 0 ) )
            const resultList = await axios.all( createFormDataList( createdCommentPkList ) )
            return resultList
        } catch ( error ) {
            console.log( "e", error )
            addToast( {
                text: "投稿に失敗しました",
                type: "error",
                position: "center"
            } )
            console.warn( error )
            console.warn( error.response.data )
        }
    }

    const createFormDataList = ( createdCommentPkList: string[] ) =>
        mediaList.map( ( media, index ) => {
            const formData = new FormData()
            formData.append( "file", media )
            formData.append( "text", "" )
            return updateComment( {
                commentPk: createdCommentPkList[ index ],
                data: formData,
                onUpload: ( e: ProgressEvent ) => onUploadEachComment( e, index )
            } )
        } )

    const generateFileName = ( media: PickedMediaType ) => media.uri.split( '/' ).slice( -1 )[ 0 ].includes( "." ) ?
        media.uri.split( '/' ).slice( -1 )[ 0 ] :
        media.uri.split( '/' ).slice( -1 )[ 0 ] + ".mp4"

    return {
        uploadPostAndAllFiles,
        commentUploadRatioList,
    }
}
