import { useState, useEffect, useRef } from 'react'

type props = {
    func: () => void
    interval: number
}
export const useInterval = ( { func, interval }: props ) => {

    const [ intervalID, setIntervalID ] = useState<NodeJS.Timeout | null>( null );
    const [ executing, setExecuting ] = useState( false );

    const intervalIDRef = useRef( intervalID )
    useEffect( () => {
        intervalIDRef.current = intervalID
    }, [ intervalID ] )

    useEffect( () => {
        if ( executing ) {
            const newIntervalID = setInterval( func, interval )
            setIntervalID( newIntervalID )
        }
        else {
            intervalIDRef.current && clearInterval( intervalIDRef.current )
        }
    }, [ executing ] )

    useEffect( () => {
        return () => { intervalIDRef.current && clearInterval( intervalIDRef.current ) }
    }, [] )

    return {
        startInterval: () => setExecuting( true ),
        stopInterval: () => setExecuting( false ),
    }
}
