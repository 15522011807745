import { useState, useEffect } from "react"
import { isWebView } from "../utils/isWebView"


const useDetectDevice = () => {

    const [ isiOS, setIsiOS ] = useState<boolean | null>( null )
    const [ isAndroid, setIsAndroid ] = useState<boolean | null>( null )
    const [ isPWA, setIsPWA ] = useState<boolean | null>( null )
    const [ isPC, setIsPC ] = useState<boolean | null>( null )
    // 信用しないで
    const [ webView, setIsWebView ] = useState<boolean | null>( null )

    useEffect( () => {
        if ( typeof window === 'undefined' ) return
        setIsWebView( isWebView() )
        let pwa = window.matchMedia( '(display-mode: standalone)' ).matches || (window.navigator as any).standalone
        const ios = !!navigator.userAgent.match( /(iPhone|iPad|iPod)/ )
        const android = !!navigator.userAgent.match( /(Android)/ )
        if ( !pwa && ( window.screen.height - document.documentElement.clientHeight < 80 ) ) { //PWAだとアドレスバーがないので
            pwa = true
        }
        setIsPWA( pwa )
        setIsiOS( ios )
        setIsAndroid( android )
        setIsPC( !( ios || android ) )
    }, [] )


    return { isiOS, isAndroid, isPWA, isPC, isWebView }
}

export default useDetectDevice
