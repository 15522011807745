import { AxiosPromise } from 'axios';
import client from '../client';

export type VideoCompressQualityType = "low" | "middle" | "high"

type videoUploadPermissionResponse = {
    canUploadVideo: boolean
    videoCompressQuality: VideoCompressQualityType
}

export const getVideoPermission = (): AxiosPromise<videoUploadPermissionResponse> =>
    client.get( `/api/video_upload_permission/` )
