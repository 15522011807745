import React, { useCallback, useContext, useRef, useState } from 'react'
import { useMediaMultiUpload } from './useMediaMultiUpload';
import { PlusIcon } from '../icons/PlusIcon';
import { ToastContext } from '../context/ToastContext';
import { ReactSortable } from "react-sortablejs";
import { useVideoUploadPermission } from './useVideoUploadPermission';
import { SingleFileItem, FileAddBox } from '../components/SingleFileItem';
import { useIsBigScreen } from './useIsBigScreen';


export type MediaItemType = {
    id: string,
    file: File
}

export const useMultipleMediaPicker = () => {

    const [ selectedMediaList, setSelectedMediaList ] = useState<MediaItemType[]>( [] );
    const fileInputRef = useRef<HTMLInputElement>( null )
    const { uploadPostAndAllFiles, commentUploadRatioList } = useMediaMultiUpload( { mediaList: selectedMediaList.map( item => item.file ) } )
    const appendMediaList = ( newMediaList: MediaItemType[] ) => setSelectedMediaList( prevSelectedMediaList => [ ...prevSelectedMediaList, ...newMediaList ] )
    const isBigScreen = useIsBigScreen()
    const pickMedia = () => fileInputRef.current?.click()
    const { addToast } = useContext( ToastContext )
    const { canUploadVideo } = useVideoUploadPermission()

    const onSelectFiles = ( files: FileList ) => {
        const duplicatedFilenames = checkDuplicatedFiles( files )
        0 < duplicatedFilenames.length && alert( duplicatedFilenames.join( "\n" ) + "\nは既に追加されています" )

        const filesWithId = Array.from( files ).map( ( file, i ) => ( {
            file,
            id: String( new Date().getTime() + i )
        } ) )
        appendMediaList( filesWithId )
    }

    const checkDuplicatedFiles = ( files: FileList ) => {
        const duplicatedFiles: string[] = []
        const currentPickedFilenames = selectedMediaList.map( item => item.file.name )
        Array.from( files ).forEach( file => {
            currentPickedFilenames.includes( file.name ) && duplicatedFiles.push( file.name )
        } )
        return duplicatedFiles
    }

    const removeItemFromSelectedList = ( selectedMediaIndex: number ) => {
        setSelectedMediaList( prevSelectedMediaList => prevSelectedMediaList.filter( ( media, index ) => index !== selectedMediaIndex ).slice() )
    }

    const alertCannotUploadVideoIfExceededVideoDurationLimit = async () => {
        canUploadVideo ?
            pickMedia() :
            addToast( {
                text: "動画のアップロード上限に達しているため、動画は送信できません。\nご注意ください",
                type: "warn",
                position: "center",
                onDismiss: pickMedia
            } )
    }

    const pickDroppedFileWithValidation = ( files: FileList ) => {
        canUploadVideo ?
            onSelectFiles( files ) :
            addToast( {
                text: "動画のアップロード上限に達しているため、動画は送信できません。\nご注意ください",
                type: "warn",
                position: "center",
                onDismiss: () => onSelectFiles( files )
            } )
    }

    const renderShowModalButton = () =>
        <div
            style={ { display: "flex", flexDirection: "row", alignItems: "center", margin: "16px 8px 12px 8px", marginTop: 64, marginBottom: 12, cursor: "pointer" } }
            onClick={ alertCannotUploadVideoIfExceededVideoDurationLimit }
        >
            <div style={ {
                height: 24, width: 24,
                display: "inline-flex", marginRight: 8,
                justifyContent: "center", alignItems: "center",
                borderRadius: 12, backgroundColor: "rgb(0, 156, 204)"
            } }>
                <PlusIcon style={ { color: "#fff", fontSize: 16 } } />
            </div>
            <input type="file" style={ { display: "none" } } multiple ref={ fileInputRef } onChange={ e => e.target.files && onSelectFiles( e.target.files ) } />

            <p style={ { lineHeight: 1.3 } }>この投稿に<b>コメント</b>として追加するファイルを選ぶ
                <span> ({ isBigScreen ? `ドラッグでファイルを並べ替えられます` : `タッチでファイルを並べ替えられます` })</span>
            </p>
        </div>


    const renderSelectedMediaThumbnails = useCallback( () => {
        const mediaCount = selectedMediaList.length
        const fileAddBoxLocateAtDefault = mediaCount % ( isBigScreen ? 3 : 2 ) === 0
        const fileAddBoxLocateAtCenterForThreeColumn = mediaCount % 3 === 1 && isBigScreen

        return (
            <div style={ { position: "relative", display: "flex", flexDirection: "row", flexWrap: "wrap", width: "100%", padding: "0 8px" } }>
                <ReactSortable
                    style={ { display: "flex", flexDirection: "row", flexWrap: "wrap", width: "100%" } }
                    list={ selectedMediaList }
                    setList={ setSelectedMediaList }>
                    { selectedMediaList.map( ( media, index ) => {
                        const uploadPersentage = Math.ceil( commentUploadRatioList[ index ] * 100 )
                        return (
                            <SingleFileItem
                                key={ media.id }
                                media={ media }
                                uploadPersentage={ uploadPersentage }
                                removeItem={ () => removeItemFromSelectedList( index ) }
                            />
                        )
                    } ) }
                </ReactSortable>
                <FileAddBox
                    pickFiles={ alertCannotUploadVideoIfExceededVideoDurationLimit }
                    appendFiles={ pickDroppedFileWithValidation }
                    locateAtDefault={ fileAddBoxLocateAtDefault }
                    locateAtCenterForThreeColumn={ fileAddBoxLocateAtCenterForThreeColumn } />
            </div> )
    }, [ selectedMediaList, commentUploadRatioList ] )


    return {
        renderShowModalButton,
        renderSelectedMediaThumbnails,
        selectedMediaList,
        setSelectedMediaList,
        uploadPostAndAllFiles,
        multiMediaUpload: !!selectedMediaList.length
    }
}
