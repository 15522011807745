import React, { useState, useEffect, FC } from "react"
import Styles from "../styles/DraftListModal.module.scss"
import { LocalStorageWrapper } from "../utils/LocalStorageWrapper"
import Modal from "../components/Modal";
import { DraftType, DraftIDType } from "../types/models/Post";

type Props = {
    modalVisible: boolean,
    closeModal: () => void,
    selectDraft: ( draft: DraftType ) => void,
    deleteDraft: ( ID: DraftIDType ) => DraftType[],
}


export const DraftListModal: FC<Props> = ( {
    modalVisible,
    closeModal,
    selectDraft,
    deleteDraft
} ) => {

    const [ draftList, setDraftList ] = useState<DraftType[]>( [] );

    useEffect( () => {
        getAndSetDraftList()
    }, [ modalVisible] )

    const getAndSetDraftList = () => {
        const savedDraftList: DraftType[] = LocalStorageWrapper.getItem( "draftList" )
        savedDraftList && setDraftList( savedDraftList )
    }

    const deleteDraftAndSetNewDraftList = async ( deleteDraftID: DraftIDType ) => {
        const newDraftList = deleteDraft( deleteDraftID )
        setDraftList( newDraftList )
    }

    return (
        <Modal visible={ modalVisible } closeModal={ closeModal } closeModalIconVisible={ false }>
            <div style={ { backgroundColor: "#fff", borderRadius: 6 } }>
                <div style={ { display: "flex", justifyContent: "space-between", padding: 12, borderBottom: "0.5px solid rgba(0,0,0,0.12)" } }>
                    <b>
                        <span
                            style={ { color: Styles.main_color, cursor: "pointer" } }
                            onClick={ closeModal }>
                            キャンセル
                        </span>
                    </b>
                    <b>下書き</b>
                    <b>
                        <span style={ { color: "rgba(0,0,0,0)" } }>
                            キャンセル
                    </span>
                    </b>
                </div>
                <div>
                    { draftList.length === 0 &&
                        <div>
                            <p style={ { paddingTop: 16, color: "rgba(0,0,0,0.54)", fontSize: 18, textAlign: "center" } }>
                                { "まだ下書きはありません" }
                            </p>
                            <p style={ { padding: 16, color: "rgba(0,0,0,0.54)", fontSize: 16, fontWeight: "bold", textAlign: "center" } }>
                                { "投稿画面左上のキャンセルボタンから保存ができます" }
                            </p>
                        </div> }
                    { draftList.map( draft =>
                        <DraftItem
                            key={ draft.ID }
                            draft={ draft }
                            selectDraft={ selectDraft }
                            deleteDraft={ deleteDraftAndSetNewDraftList } />
                    ) }
                </div>
            </div>
        </Modal > )
}

type DraftItemProps = {
    draft: DraftType,
    selectDraft: ( draft: DraftType ) => void,
    deleteDraft: ( ID: DraftIDType ) => void,
}

const DraftItem: FC<DraftItemProps> = ( {
    draft,
    selectDraft,
    deleteDraft
} ) => {

    const deleteConfirm = () =>
        window.confirm( `この下書きを破棄してよろしいですか？` ) &&
        deleteDraft( draft.ID )

    return (
        <div
            onClick={ () => selectDraft( draft ) }
            className={ Styles.draft_item }
            style={ {
                display: "flex", alignSelf: "stretch", flexDirection: "row",
                alignItems: "center", justifyContent: "space-between",
                padding: 16,
                borderBottomColor: "rgba(0,0,0,0.12)", borderBottomWidth: 0.5, borderBottomStyle: "solid",
                cursor: "pointer"
            } }>
            <div style={ { display: "inline-flex", flexDirection: "column", } }>
                { !!draft.text &&
                    <p style={ {
                        fontSize: 14,
                        lineHeight: 1.4,
                        flexWrap: "wrap"
                    } }>
                        { draft.text }
                    </p> }
            </div>
            <div
                style={ { display: "inline-flex", alignItems: "center" } }
                onClick={ ( e ) => { deleteConfirm(); e.stopPropagation() } }>
                <div className={ Styles.delete_button }>
                    削除する
                </div>
            </div>
        </div>
    )
}
