import React, { CSSProperties, FC, memo } from 'react'
import Styles from "../styles/SingleFileItem.module.scss"
import LocalFile from './LocalFile'
import { MediaItemType } from "../hooks/useMultipleMediaPicker"
import { ProgressCircle } from './ProgressCircle'
import { useFileListDrop } from '../hooks/useFileDrop'
import { PlusIcon } from '../icons/PlusIcon'
import { CloseIcon } from '../icons/CloseIcon'
import { useIsBigScreen } from '../hooks/useIsBigScreen'

type Props = {
    media: MediaItemType,
    uploadPersentage: number,
    removeItem: () => void
}

export const SingleFileItem = memo( ( { media, uploadPersentage, removeItem }: Props ) => {

    return (
        <div
            className={ Styles.item_container }
            style={ { position: "relative", display: "inline-flex", cursor: "pointer", borderRadius: 4, overflow: "hidden" } }>
            <div
                className={ Styles.remove_file_icon_container }
                onClick={ removeItem }>
                <CloseIcon
                    className={ Styles.remove_file_icon } />
            </div>
            <LocalFile
                file={ media.file }
                getVideoDuration={ () => { } }
                trimmedVideo={ null }
                setTrimmedVideo={ () => { } }
                videoTrimEnabled={ false } />
            { !!uploadPersentage &&
                <div style={ {
                    position: "absolute",
                    top: 4, right: 4,
                    justifyContent: "center",
                    alignItems: "center",
                    height: 48, width: 48,
                    borderRadius: 24,
                    backgroundColor: "#fff",
                    zIndex: 2
                } }>
                    <ProgressCircle
                        backgroundColor="#rgba(255,255,255,1)"
                        unreachedColor={ "MAIN_LIGHT_COLOR" }
                        progressColor={ "MAIN_COLOR" }
                        size={ 48 }
                        circleWidth={ 8 }
                        ratio={ uploadPersentage / 100 }>
                        <p style={ { fontSize: 13 } }>{ uploadPersentage }%</p>
                    </ProgressCircle>
                </div> }
        </div>
    )
} )


type FileAddBoxProps = {
    pickFiles: () => void
    appendFiles: ( files: FileList ) => void
    locateAtDefault: boolean
    locateAtCenterForThreeColumn: boolean
}

export const FileAddBox: FC<FileAddBoxProps> = ( { pickFiles, appendFiles, locateAtDefault, locateAtCenterForThreeColumn } ) => {
    const isBigScreen = useIsBigScreen()
    const fileDropProps = useFileListDrop( appendFiles )
    const transform: CSSProperties = locateAtDefault ?
        {} : {
            position: "absolute",
            right: 0,
            marginRight: 0,
            marginBottom: 0,
            transform: `translate3d( ${ locateAtCenterForThreeColumn ? "calc( -100% - 8px )" : 0 }, calc( -100% - 8px ), 0 )`,
        }
    return (
        <div
            style={ { width: "100%", position: "relative" } }
            { ...fileDropProps }>
            <div
                onClick={ pickFiles }
                className={ Styles.item_container }
                style={ {
                    ...transform,
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderStyle: "dashed",
                    borderRadius: 4,
                    borderColor: "rgba(0,0,0,0.54)",
                    borderWidth: 2,
                    cursor: "pointer"
                } }>

                <div style={ { display: "inline-flex", flexDirection: "column", justifyContent: "center", alignItems: "center" } }>
                    <PlusIcon style={ { color: "rgba(0,0,0,0.54)", fontSize: 22, marginTop: 16 } } />
                    <p style={ { marginTop: 8, color: "rgba(0,0,0,0.54)", lineHeight: 1.3, fontSize: 13, textAlign: "center" } }>{ `ファイルを選択${ isBigScreen ? "\nまたは\nドラッグ&ドロップ" : "" }` }</p>
                </div>
            </div>
        </div>
    )
}
