import { AxiosPromise } from 'axios';
import client from '../client';


type Params = {
    commentPk: string
    data: FormData
    onUpload: ( e: ProgressEvent ) => void
}

export const updateComment = ( { commentPk, data, onUpload }: Params ): AxiosPromise<{}> => {
    return client.patch( `/api/comment/${ commentPk }/`, data, {
        onUploadProgress: onUpload
    } )
}
