export const LocalStorageWrapper = {
    getItem: ( key: string ):any => {
        const stringifiedData = localStorage.getItem( key )
        return stringifiedData ?
            JSON.parse( stringifiedData ) :
            stringifiedData
    },
    setItem: ( key: string, obj: any ) =>
        localStorage.setItem( key, JSON.stringify( obj ) )
}
