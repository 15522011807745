import React, { FC, useState, useEffect } from 'react'
import Styles from "../styles/ProgressCircle.module.scss"
import clsx from "clsx"

type ColorValue = string
type Props = {
    size: number
    ratio: number
    backgroundColor: ColorValue
    progressColor: ColorValue
    unreachedColor: ColorValue,
    circleWidth: number
}

export const ProgressCircle: FC<Props> = ( { size, ratio, backgroundColor, progressColor, unreachedColor, circleWidth, children } ) => {
    const ratioIsUnder50Percent = ratio < 0.5
    return (
        <div className={ Styles.circle }>
            <div
                className={ clsx( Styles.right_circle, !ratioIsUnder50Percent && Styles.reached_right_circle ) }
                style={ ratioIsUnder50Percent ? { transform: `rotate(${ 360 * ratio }deg)` } : {} }></div>
            <div className={ Styles.inner_circle }>
                { children }
            </div>
            <div className={ Styles.left_circle } style={ ratioIsUnder50Percent ? {} : { transform: `rotate(${ 360 * ratio - 180 }deg)` } }></div>
        </div>
    )
}
