import React, { useState, useRef, useEffect, useContext } from "react"
import Styles from "../styles/PostForm.module.scss";
import Layout from "../components/Layout"
import SEO from "../components/seo"
import TextareaAutosize from 'react-textarea-autosize';
import clsx from "clsx"
import { navigate } from "gatsby";
import GeneralButton from "../components/atoms/GeneralButton";
import Tag from "../components/atoms/Tag";
import Modal from "../components/Modal";
import SelectBoxWithInputSearch from "../components/SelectBoxWithInputSearch";
import ConfigList from "../templates/ConfigList";
import LocalFile from "../components/LocalFile"
import amplitude, { logErrorEvent, logEvent } from "../Analytics";
import useDetectDevice from "../hooks/useDetectDevice"
import { useMediaQuery } from 'react-responsive'
import { MentionModal } from "../components/MentionModal"
import { ToastContext } from '../context/ToastContext';
import { LocalStorageWrapper } from "../utils/LocalStorageWrapper";
import ActionSheet from "../components/ActionSheet"
import { DraftListModal } from "../components/DraftListModal"
import { sleep } from "../functions/sleep"
import RemoteFile from "../components/RemoteFile";
import { DraftType, isDetailPost, isImagePost, isTextPost, isVideoPost, PostDetailType } from "../types/models/Post";
import { ConfigType } from "../types/models/Config";
import { SelectButtonType } from "../types/models/SelectButton";
import { TagType, Tag as TagAlias } from "../types/models/Tag";
import { ActionSheetMenuType } from "../types/models/ActionSheetMenu";
import { useInterval } from "../hooks/useInterval";
import { POST_SAVE_INTERVAL } from "../constant/const";
import { ToastPosition, ToastSize, ToastType } from "../types/models/Toast";
import client from "../apis/client";
import { FileIcon } from "../icons/FileIcon";
import { TagIcon } from "../icons/TagIcon";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { useLocalSettings } from "../hooks/useLocalSettings";
import { useMultipleMediaPicker } from "../hooks/useMultipleMediaPicker";
import { createPost } from "../apis/post/createPost";
import { useFileDrop } from "../hooks/useFileDrop";


const publishNameOptionList = [ {
    value: true,
    label: "実名",
    isSelected: true
}, {
    value: false,
    label: "匿名",
    isSelected: false
} ]

const publishRangeConfigList = [ {
    value: "public",
    label: "チーム外にも公開",
    isSelected: false
}, {
    value: "limitedly_public",
    label: "限定公開",
    isSelected: false
}, {
    value: 'timeline',
    label: "チーム内に公開",
    isSelected: true
}, {
    value: 'myown',
    label: "自分にのみ",
    isSelected: false
} ]

const publishRangeConfigListWithoutTeam = [ {
    value: "public",
    label: "公開",
    isSelected: true
}, {
    value: "limitedly_public",
    label: "限定公開",
    isSelected: false
}, {
    value: 'myown',
    label: "自分にのみ",
    isSelected: false
} ]

const postCreateInitialConfigList = [
    { label: "実名/匿名", name: "is_real_name", list: publishNameOptionList },
    { label: "公開範囲", name: "type", list: publishRangeConfigList }
]

const PostPage = ( {
    location
}: any ) => {

    const defaultPost = location.state ?
        location.state.post :
        null
    const initialConfigList =
        defaultPost ?
            [
                {
                    label: "公開範囲",
                    name: "type",
                    list: publishRangeConfigList.map( option =>
                        option.value === defaultPost.type ?
                            { ...option, isSelected: true } :
                            { ...option, isSelected: false }
                    )
                }
            ] :
            postCreateInitialConfigList

    const device = useDetectDevice()
    const isBigScreen = useMediaQuery( { query: '(min-width: 900px)' } )

    const [ post, setPost ] = useState<PostDetailType | null>( defaultPost );
    const [ text, setText ] = useState( post ? post.text : "" )
    const textRef = useRef( text )
    useEffect( () => {
        textRef.current = text
    }, [ text ] )

    const [ file, setFile ] = useState<File | null>( null )
    const [ trimmedVideo, setTrimmedVideo ] = useState<Blob | null>( null );
    const [ videoDuration, setVideoDuration ] = useState( 0 )
    const [ tagList, setTagList ] = useState<SelectButtonType<string>[]>( [] )
    const localSettings = useLocalSettings( {} )

    const tagListRef = useRef( tagList )
    useEffect( () => {
        tagListRef.current = tagList
    }, [ tagList ] )

    const [ myTagList, setMyTagList ] = useState<SelectButtonType<string>[]>( [] )
    const myTagListRef = useRef( myTagList )
    useEffect( () => {
        myTagListRef.current = myTagList
    }, [ myTagList ] )

    const [ userTeam, setUserTeam ] = useState( null )
    const [ configList, setConfigList ] = useState<ConfigType[]>( initialConfigList )
    const configListRef = useRef( configList )
    useEffect( () => {
        configListRef.current = configList
    }, [ configList ] )

    const [ tagModalVisible, setTagModalVisible ] = useState( false )
    const [ configModalVisible, setConfigModalVisible ] = useState( false )
    const [ uploadRatio, setUploadRatio ] = useState( 0 )
    const [ uploading, setUploading ] = useState( false )
    const [ youtubeLinkVisible, setYoutubeLinkVisible ] = useState( true )
    const [ draftListModalVisible, setDraftListModalVisible ] = useState( false );
    const [ selectedDraft, setSelectedDraft ] = useState<DraftType | null>( null );
    const selectedDraftRef = useRef( selectedDraft )
    useEffect( () => {
        selectedDraftRef.current = selectedDraft
    }, [ selectedDraft ] )

    const [ canUploadVideo, setCanUploadVideo ] = useState( true );
    const [ isFileDialogOpen, setIsFileDialogOpen ] = useState( false );
    const [ saveDraftOrDiscardPostActionSheetVisible, setSaveDraftOrDiscardPostActionSheetVisible ] = useState( false );
    const isFileDialogOpenRef = useRef( isFileDialogOpen )
    const { addToast } = useContext( ToastContext );
    useInterval( {
        func: saveDraftForAuto,
        interval: POST_SAVE_INTERVAL
    } )
    const { uploadPostAndAllFiles, renderShowModalButton, multiMediaUpload,
        renderSelectedMediaThumbnails } = useMultipleMediaPicker()


    const textarea = useRef<HTMLTextAreaElement>( null );
    const fileInputRef = useRef<HTMLInputElement>( null )

    useEffect( () => {
        isFileDialogOpenRef.current = isFileDialogOpen
    }, [ isFileDialogOpen ] )

    useEffect( () => {
        setTrimmedVideo( null )
    }, [ file ] )

    const fileDropProps = useFileDrop( setFile )

    useEffect( () => {
        textarea.current && textarea.current.focus()
        componentDidMount()
    }, [] )

    useEffect( () => {
        applyDraft()
        setDraftListModalVisible( false )
    }, [ selectedDraft ] )

    const applyDraft = () => {
        if ( !selectedDraft ) return
        setText( selectedDraft.text )
        setConfigList( selectedDraft.configList )

        const newTagList = tagList.map( tag => selectedDraft.tagList.includes( tag.value ) ?
            { ...tag, isSelected: true } :
            { ...tag, isSelected: false }
        )
        setTagList( newTagList )

        const newMyTagList = myTagList.map( tag => selectedDraft.myTagList.includes( tag.value ) ?
            { ...tag, isSelected: true } :
            { ...tag, isSelected: false }
        )
        setMyTagList( newMyTagList )
    }

    function saveDraftForAuto () {
        if ( post ) return
        if ( !selectedDraftRef.current && !textRef.current ) return
        const draft = {
            text: textRef.current,
            tagList: tagListRef.current.filter( tag => tag.isSelected ).map( tag => tag.value ),
            myTagList: myTagListRef.current.filter( tag => tag.isSelected ).map( tag => tag.value ),
            configList: configListRef.current,
            ID: selectedDraftRef.current ? selectedDraftRef.current.ID : String( new Date().getTime() )
        }
        const saveDraftList: DraftType[] = LocalStorageWrapper.getItem( "draftList" ) || []
        const draftList = saveDraftList.filter( savedDraft => savedDraft.ID !== draft.ID )
        const newDraftList = draftList ?
            [ ...draftList, draft ] :
            [ draft ]
        LocalStorageWrapper.setItem( "draftList", newDraftList )
        if ( !selectedDraftRef.current ) setSelectedDraft( draft )
        addToast( {
            text: "下書きに一時保存しました",
            type: ToastType.INFO,
            duration: 3000,
            position: ToastPosition.TOP,
            size: ToastSize.SMALL
        } )
    }

    function saveDraft () {
        const draft = {
            text,
            tagList: tagList.filter( tag => tag.isSelected ).map( tag => tag.value ),
            myTagList: myTagList.filter( tag => tag.isSelected ).map( tag => tag.value ),
            configList,
            ID: selectedDraft ? selectedDraft.ID : String( new Date().getTime() )
        }
        const saveDraftList: DraftType[] = LocalStorageWrapper.getItem( "draftList" ) || []
        const draftList = saveDraftList.filter( savedDraft => savedDraft.ID !== draft.ID )
        const newDraftList = draftList ?
            [ ...draftList, draft ] :
            [ draft ]
        LocalStorageWrapper.setItem( "draftList", newDraftList )
        addToast( { text: "下書きを保存しました", type: "success", duration: 3000, position: "top" } )
        window.history.back()
    }

    const deleteDraft = ( draftID: string ) => {
        const savedDraftList: DraftType[] = LocalStorageWrapper.getItem( "draftList" )
        const newDraftList = savedDraftList.filter( savedDraft => savedDraft.ID !== draftID )
        LocalStorageWrapper.setItem( "draftList", newDraftList )
        return newDraftList
    }

    const componentDidMount = async () => {
        await client.get( `/api/video_upload_permission/` )
            .then( response => setCanUploadVideo( response.data.canUploadVideo ) )
            .catch( error => console.log( error.response ) )

        if ( post ) return

        await client.get( `/api/tag/` )
            .then( response => {
                const data = response.data
                const tag_list: TagType[] = data.tag_list
                const tagList = tag_list.map( tag => ( {
                    value: tag.pk,
                    label: tag.name,
                    type: tag.type,
                    isSelected: false
                } ) )
                const mytag_list: TagType[] = data.mytag_list
                const myTagList = mytag_list.map( tag => ( {
                    value: tag.pk,
                    label: tag.name,
                    type: tag.type,
                    isSelected: false
                } ) )
                setTagList( tagList )
                setMyTagList( myTagList )
                setUserTeam( data.team )
                if ( !data.team ) {
                    const newConfigList = configList.map( config =>
                        config.name !== "type" ?
                            config :
                            { ...config, list: publishRangeConfigListWithoutTeam }
                    )
                    setConfigList( newConfigList )
                }
            } )
            .catch( error => { console.log( error.response ) } )
    }

    const generateFileName = ( file: File ) => {
        let fileName = 90 < file.name.length ?
            file.name.slice( file.name.length - 90 ) :
            file.name
        fileName = fileName.includes( "." ) ?
            fileName :
            fileName + ".mp4"
        return fileName
    }

    const attatchFile = ( data: FormData ) => {
        if ( !file ) return
        const fileName = generateFileName( file )
        trimmedVideo ?
            data.append( "file", trimmedVideo, fileName ) :
            data.append( "file", file, fileName )
    }

    const attatchNullToFile = ( data: FormData ) => data.append( "file", "null" )

    const attatchTag = ( data: FormData ) =>
        tagList.concat( myTagList )
            .filter( tag => tag.isSelected )
            .forEach( tag => {
                data.append( "tags", tag.value )
            } )


    const attatchConfig = ( data: FormData ) =>
        configList.forEach( range => {
            data.append( range.name, range.list.filter( option => option.isSelected )[ 0 ].value )
        } )

    const attatchText = ( data: FormData ) => data.append( "text", text )

    const submit = async () => {
        if ( ! await validateUploadData() ) return

        const data = new FormData()
        attatchFile( data )
        attatchText( data )
        attatchTag( data )
        attatchConfig( data )
        setUploading( true )
        try {
            if ( multiMediaUpload ) {
                await uploadPostAndAllFiles( {
                    postFormData: data,
                    onPostUpload: onUpload
                } )
            }
            else {
                await createPost( {
                    data,
                    onUpload
                } )
            }
            let fileType = ( file && file.type ) ? file.type.split( "/" )[ 0 ] : "text"
            fileType === "video" ?
                logEvent( amplitude, "Action-Post", { fileType, duration: videoDuration, web: true } ) :
                logEvent( amplitude, "Action-Post", { fileType, web: true } )

            setText( "" )
            setFile( null )
            setUploading( false )
            selectedDraft && deleteDraft( selectedDraft.ID )
            addToast( { text: "投稿しました", type: "success" } )
            multiMediaUpload && addToast( { text: "動画の反映に1分程度、時間がかかる場合があります", type: "info", duration: 5000 } )
            !localSettings.serialPost && navigate( "/home", { state: { afterPost: true } } )
        }
        catch ( error ) {
            console.log( error )
            setUploading( false )
            if ( error.response ) {
                if ( error.response.status === 402 ) {
                    addToast( {
                        text: error.response.data.message,
                        type: "error",
                        duration: 6000
                    } )
                }
                else {
                    addToast( {
                        text: "変更に失敗しました",
                        type: "error"
                    } )
                }
            }
            alert( error )
            // console.log( error.response )
            const fileType = ( file && file.type ) ? file.type.split( "/" )[ 0 ] : "text"
            const optionProperty = fileType === "video" ?
                { fileType, duration: videoDuration, web: true } :
                { fileType, web: true }
            logErrorEvent( amplitude, "Error-Post", error, optionProperty )

            if ( error.response && error.response.data ) {
                for ( let key in error.response.data ) {
                    Array.isArray( error.response.data[ key ] ) && error.response.data[ key ].forEach( ( e: string ) => {
                        alert( e )
                    } )
                }
            }
        }
    }

    const validateUploadData = async ( update = false ) => {
        if ( uploading ) return false

        if ( !update ) {
            if ( !text && !file ) {
                // alert( 'テキストを入力するか、\nファイルを選択してください' );
                addToast( { text: "テキストを入力するか、\nファイルを選択してください", type: "warn" } )
                return false
            }
        }
        if ( isDetailPost( post ) ) {
            if ( !text && isTextPost( post ) && !file ) {
                addToast( { text: "テキストを入力するか、\nファイルを選択してください", type: "warn" } )
                return false
            }
            if ( !text && !post.file && !file ) {
                addToast( { text: "テキストを入力するか、\nファイルを選択してください", type: "warn" } )
                return false
            }
        }
        if ( text.length > 2000 ) {
            addToast( { text: "テキストは2000字までです\nコメントに文章を分けるなどしてください", type: "warn" } )
            return false
        }

        return true
    }

    const update = async () => {
        if ( ! await validateUploadData( true ) ) return
        if ( !isDetailPost( post ) ) return

        const data = new FormData()
        attatchText( data )
        attatchConfig( data )
        attatchFile( data )
        if ( !file && !post.file ) attatchNullToFile( data ) // 削除


        setUploading( true )
        await client.patch( `/api/post/${ post.pk }/`, data, {
            onUploadProgress: onUpload,
            // リクエストのキャンセルする時に使うキャンセルトークンの設定
            // cancelToken: source.token
        } ).
            then( ( response ) => {
                setText( "" )
                setFile( null )
                setUploading( false )
                addToast( { text: "変更しました", type: "success" } )
                // alert( "変更しました" )
                logEvent( amplitude, "Action-PostUpdate", { web: true } )
                window.history.back()
            } )
            .catch( error => {
                setUploading( false )
                if ( error.response ) {
                    if ( error.response.status == 402 ) {
                        addToast( {
                            text: error.response.data.message,
                            type: "error",
                            duration: 6000
                        } )
                    }
                    else {
                        addToast( {
                            text: "変更に失敗しました",
                            type: "error"
                        } )
                    }
                }
                console.log( error.response )
                logErrorEvent( amplitude, "Error-Post", error, { web: true } )
                addToast( { text: "変更に失敗しました", type: "error" } )
            } )
    }

    const getVideoDuration = ( duration: number ) => setVideoDuration( duration )

    const onUpload = ( e: ProgressEvent ) => {
        const ratio = ( e.loaded / e.total )
        setUploadRatio( ratio )
    }

    const onChangeSelectButton = ( boxName: string, buttonList: SelectButtonType<string>[] ) => {
        if ( boxName === TagAlias.TAG ) setTagList( buttonList )
        else if ( boxName === TagAlias.MYTAG ) setMyTagList( buttonList )
    }

    const onChangeConfigButton = ( name: string, value: string, isSelected: boolean ) => {
        const newConfigList = configList.map( range => (
            range.name !== name ?
                range :
                {
                    ...range, list: range.list.map( r =>
                        r.value !== value ?
                            { ...r, isSelected: false } :
                            { ...r, isSelected: true }
                    )
                }

        ) ).slice()
        setConfigList( newConfigList )
    }


    const onChangeText = ( name: string, value: string ) => setText( value )

    const onChangeFile = () => {
        if ( !fileInputRef.current ) return
        const files = fileInputRef.current.files
        if ( !files ) return
        const file = files[ 0 ]

        if ( file.size > ( 200 * 1000 * 1000 ) ) {
            addToast( { text: "動画は200メガバイトまでです\nトリミングをするか、画質を落としてください", type: "warn" } )
            // alert( '動画は200メガバイトまでです\nトリミングをするか、画質を落としてください' )
            return
        }

        if ( file.size > ( 100 * 1000 * 1000 ) ) {
            addToast( { text: "サイズの大きなファイルです\n投稿ページが重くなる場合があります", type: "warn" } )
            // alert( 'サイズの大きなファイルです\n投稿ページが重くなる場合があります' )
            if ( !window.confirm( "このまま添付しますか？" ) ) {
                return
            }
        }

        if ( file.name.includes( "." ) ) {
            setFile( file )
            return
        }

        if ( window.confirm( "拡張子なしのこのファイルは、動画として扱われます。\nよろしいですか？" ) ) {
            setFile( file )
            return
        }
        addToast( { text: "適切な拡張子に変換してからアップロードしてください", type: "warn" } )
        // alert( "適切な拡張子に変換してからアップロードしてください" )
        fileInputRef.current.value = ""
    }

    const cancelEdit = () => {
        const originalText = post ?
            post.text : ""
        if ( post && text === originalText ) {
            window.history.back()
            return
        }
        if ( text ||
            myTagList.filter( tag => tag.isSelected ).length ||
            tagList.filter( tag => tag.isSelected ).length ) {
            setSaveDraftOrDiscardPostActionSheetVisible( true )
            return
        }
        window.history.back()
    }

    const renderAttatchedTagList = () => {
        const attatchedTagList = tagList
            .filter( tag => tag.isSelected )
            .map( tag =>
                <Tag
                    tag={ {
                        ...tag,
                        name: tag.label as string,
                        pk: tag.value,
                        type: TagAlias.TAG
                    } }
                    style={ { marginLeft: 4, marginBottom: 4 } } /> )
        const attatchedMyTagList = myTagList
            .filter( tag => tag.isSelected )
            .map( tag =>
                <Tag
                    tag={ {
                        ...tag,
                        name: tag.label as string,
                        pk: tag.value,
                        type: TagAlias.MYTAG
                    } }
                    style={ { marginLeft: 4, marginBottom: 4 } } /> )
        return attatchedTagList.concat( attatchedMyTagList )
    }

    const renderYoutubeOpenLinkButton = () =>
        <div
            className={ Styles.youtube_open_button_container }>
            <GeneralButton
                style={ { background: "red", margin: "auto", width: "100%", textAlign: "left" } }
                title={
                    <OutboundLink
                        style={ { borderRadius: 8, background: "red" } }
                        href={ device.isPC ? "https://youtube.com" : "youtube://" }
                        target="_blank">
                        <p style={ { color: "white", fontSize: 20 } }>
                            YouTubeを開く
                        </p>
                        <p style={ { color: "white", fontSize: 12 } }>YouTubeのリンクはClubCloud内で再生できます</p>
                        <p style={ { color: "white", fontSize: 12 } }>YouTubeで投稿後、リンクをClubCloudに投稿しましょう</p>
                    </OutboundLink> } />
            <p className={ Styles.youtube_explain }>
                { "再生時間の長い動画は、YouTubeにアップして、リンクをClubCloudで扱うことでページが軽くなります\nただし、動画比較機能と描き込み機能はYouTubeの動画には使えません" }
            </p>
        </div>

    const renderActionSheet = () => {
        const menuList: ActionSheetMenuType[] = [
            {
                name:
                    <>
                        <p style={ { fontSize: "1.2rem", color: "#009ccc" } }>下書きに保存する</p>
                        <p>(ただし、ファイルは保存されません)</p>
                    </>,
                func: () => saveDraft()
            },
            { name: "破棄して戻る", func: () => window.history.back(), color: "red" },
        ]
        return (
            <ActionSheet
                menuList={ menuList }
                visible={ saveDraftOrDiscardPostActionSheetVisible }
                hideActionSheet={ () => setSaveDraftOrDiscardPostActionSheetVisible( false ) } /> )
    }

    const focusInputAndMoveCursor = ( cursorPosition: number ) => {
        if ( !textarea.current ) return
        textarea.current.focus()
        textarea.current.setSelectionRange( cursorPosition, cursorPosition );
    }

    const pickMedia = async () => {
        if ( canUploadVideo ) {
            fileInputRef.current && fileInputRef.current.click()
        }
        else {
            setIsFileDialogOpen( false )
            !canUploadVideo && addToast( {
                text: "動画のアップロード上限に達しているため、動画は送信できません。\nご注意ください",
                type: "warn",
                position: "center",
                onDismiss: () => {
                    fileInputRef.current && fileInputRef.current.click()
                    setIsFileDialogOpen( true )
                }
            } )
            await sleep( 4000 )
            !isFileDialogOpenRef.current && fileInputRef.current && fileInputRef.current.click()
            setIsFileDialogOpen( true )
        }
    }

    return (
        <Layout rightColumnContent={ renderYoutubeOpenLinkButton() }>
            <>
                <SEO title="PostForm" />
                <div
                    className={ Styles.container } { ...fileDropProps }>
                    <div className={ Styles.button_container }>
                        <b><span style={ { color: Styles.main_color, marginLeft: 8 } } onClick={ () => cancelEdit() }>キャンセル</span></b>
                        { uploading &&
                            <div className={ Styles.progress_bar_wrapper }>
                                <progress value={ uploadRatio * 100 } max={ 100 } />
                                <span>{ Math.floor( uploadRatio * 100 ) }%</span>
                            </div>
                        }
                        <div style={ { display: "inline-flex", alignItems: "center" } }>
                            { !post &&
                                <b>
                                    <span
                                        style={ { color: Styles.main_color, marginRight: 16 } }
                                        onClick={ () => setDraftListModalVisible( true ) }>
                                        下書き
                                    </span>
                                </b> }
                            <GeneralButton
                                onClick={ () => { post ? update() : submit() } }
                                title={ post ? "変更" : "投稿" }
                                loading={ uploading }
                                style={ { padding: "8px 20px", borderRadius: 20 } } />
                        </div>
                    </div>
                    <TextareaAutosize
                        onFocus={ () => setYoutubeLinkVisible( false ) }
                        onBlur={ () => setYoutubeLinkVisible( true ) }
                        onChange={ e => onChangeText( "text", e.target.value ) }
                        // inputRef={ tag => textarea.current = tag }
                        inputRef={ textarea }
                        name="text"
                        placeholder={ "例\n  【技名】\n  【意識していること】\n  【教えて欲しいこと】" }
                        autoFocus
                        value={ text }
                        maxRows={ 10 }
                        minRows={ isBigScreen ? 6 : 4 }
                        className={ `${ Styles.textarea } ${ post && text ? Styles.text_contained_textarea : "" }` }>
                    </TextareaAutosize>
                    <MentionModal text={ text } setText={ setText } focusInput={ focusInputAndMoveCursor } changeLayout={ () => { } } />
                    { !post &&
                        <div className={ Styles.attatched_tag_list }>
                            { renderAttatchedTagList() }
                        </div> }
                    <div className={ Styles.option_container_wrapper }>
                        <div
                            className={ Styles.option_container }
                            style={ post ? { borderTop: "none", paddingTop: 0 } : {} }>
                            <FileIcon
                                className={ clsx( Styles.icon, Styles.file_icon ) }
                                onClick={ () => pickMedia() } />
                            <input
                                ref={ fileInputRef }
                                type="file"
                                name=""
                                id=""
                                onChange={ ( e ) => onChangeFile() } />
                            { !post && <TagIcon
                                className={ clsx( Styles.icon, Styles.tag_icon ) }
                                onClick={ () => setTagModalVisible( true ) } /> }
                            <button
                                className={ Styles.publish_range_button }
                                onClick={ () => setConfigModalVisible( true ) } >
                                { configList.filter( config => config.name === "type" )[ 0 ].list.filter( option => option.isSelected )[ 0 ].label }
                            </button>
                        </div>
                    </div>
                    <div style={ { width: "100%", textAlign: "center" } }>
                        { file &&
                            <LocalFile
                                file={ file }
                                getVideoDuration={ getVideoDuration }
                                trimmedVideo={ trimmedVideo }
                                setTrimmedVideo={ setTrimmedVideo }
                                removeFile={ () => {
                                    if ( fileInputRef.current ) fileInputRef.current.value = "";
                                    setFile( null )
                                    setTrimmedVideo( null )
                                } } /> }
                        <div>
                            { post && post.file && !file &&
                                <RemoteFile
                                    fileURL={ post.file }
                                    fileType={ isVideoPost( post ) ? "video" :
                                        isImagePost( post ) ?
                                            "image" :
                                            "" }
                                    getVideoDuration={ getVideoDuration }
                                    // trimmedVideo={ () => { } }
                                    // setTrimmedVideo={ setTrimmedVideo }
                                    removeFile={ () => {
                                        // post.file = null
                                        setPost( { ...post, file: undefined } )
                                        // fileInputRef.current.value = "";
                                        // setFile( null )
                                        // setTrimmedVideo( null )
                                    } }
                                /> }
                        </div>
                    </div>
                    { !post && renderShowModalButton() }
                    { !post && renderSelectedMediaThumbnails() }
                    { youtubeLinkVisible && !isBigScreen && renderYoutubeOpenLinkButton() }
                </div>
                <Modal visible={ tagModalVisible } closeModal={ () => setTagModalVisible( false ) }>
                    <>
                        { userTeam &&
                            <SelectBoxWithInputSearch
                                style={ { marginBottom: "48px", color: "#fff", backgroundColor: "rgba(0,0,0,0)" } }
                                label="チームタグ"
                                buttonList={ tagList }
                                onChangeSelectButton={ onChangeSelectButton }
                                boxName={ TagAlias.TAG }
                                type="checkbox"
                                placeholder="チームタグを検索" /> }
                        <SelectBoxWithInputSearch
                            style={ { marginBottom: "48px", color: "#fff", backgroundColor: "rgba(0,0,0,0)" } }
                            label="Myタグ"
                            buttonList={ myTagList }
                            onChangeSelectButton={ onChangeSelectButton }
                            boxName={ TagAlias.MYTAG }
                            type="checkbox"
                            placeholder="Myタグを検索" />
                    </>
                </Modal>
                <Modal visible={ configModalVisible } closeModal={ () => setConfigModalVisible( false ) }>
                    <ConfigList
                        title="投稿オプション"
                        description={ "限定公開だと、他チームのタイムラインや検索には表示されませんが、URLを知っている方なら誰でもアクセスできます" }
                        configList={ configList.filter( config => config.name === "type" ) }
                        onChangeConfigButton={ onChangeConfigButton } />
                </Modal>
                { !post && draftListModalVisible &&
                    <DraftListModal
                        modalVisible={ draftListModalVisible }
                        closeModal={ () => setDraftListModalVisible( false ) }
                        selectDraft={ setSelectedDraft }
                        deleteDraft={ deleteDraft } /> }
                { renderActionSheet() }
            </>
        </Layout>
    );
}

export default PostPage
